import React, { useEffect, useState } from 'react';
// import styles from './DateRangePicker.module.scss';
import styles from './DateRangePicker.module.scss';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/style.css';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);


const DateRangerPicker = (props) => {
  const { searchFontXS = '', onDateRangeChange = () => { }, onClickApplyCb = () => {} } = props;
  const [predictedSearch, setPredictedSearch] = useState();
  const [search, setSearch] = useState('');

  const [isEnterPressed, setEnterPressed] = useState(false);
  const [onFocusBorderColorChange, setOnFocusBorderColorChange] = useState(false);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [isDisabled,setIsDisabled] = useState(true);
  const DATE_FORMAT = useSelector(state => state?.uiState?.remoteConfig?.date_format || 'MM/DD/YYYY');
  const currentDate = new Date();
  const from = new Date();
  from.setDate(currentDate.getDate() - 2); // Set 'from' to two days ago
  const to = new Date(); // 'to' is the current date

  // Calculate minDate and maxDate
  const maxDate = new Date(); // Today's date
  const minDate = dayjs(maxDate).subtract(3, 'month').toDate(); // 3 months before today

  

  const placeholderValue = `${dayjs(from).format(DATE_FORMAT)} - ${dayjs(to).format(DATE_FORMAT)}`;

  const [selected, setSelected] = useState({
    from: from,
    to: to,
  });
  const [selectedDateRange, setSelectedDateRange] = useState(placeholderValue);
  const [month, setMonth] = useState(new Date());


  useEffect(() => {
    const res = formatSelectedDates();
    onDateRangeChange(selected);
  }, [selected]);

  const formatSelectedDates = () => {
    if (!selected?.from || !selected?.to) return null;
    return {
      startTime: dayjs(selected.from).format('YYYY-MM-DD'),
      endTime: dayjs(selected.to).format('YYYY-MM-DD'),
    };
  };

  const formatDateRange = (selected) => {
    console.log("DEBUG formatDateRange called")
    if (!selected.from || !selected.to) return ''; // Return an empty string if the range is not selected
    const formattedFrom = dayjs(selected.from).format(DATE_FORMAT);
    const formattedTo = dayjs(selected.to).format(DATE_FORMAT);
    setSelectedDateRange(`${formattedFrom} - ${formattedTo}`);
  };

  const handleSelectedMonth = (range) => {
    console.log("DEBUG handleSelectedMonth called")

    if (range?.from) {
      // Set the month state to the start of the selected range
      setMonth(range.from);
    }
  };

  const handleMonthChange = (newMonth) => {
    setMonth(newMonth);
  };

  const handleCalendarClose = () => {
    setIsCalendarOpen(!isCalendarOpen)
    setIsDisabled(true)
    if (selectedDateRange) {
      const dates = selectedDateRange.split(' - '); // Split the range string
      if (dates.length === 2) {
        const from = dayjs(dates[0], DATE_FORMAT).toDate();
        const to = dayjs(dates[1], DATE_FORMAT).toDate();
        setSelected({ from, to }); // Update the selected state
      }
    }
  };

  return (
    <div className={styles.dateRangeCalendarContainer}>
      <div className={styles.search_wrapper}>
        {/* <Hint options={predictedSearch} allowTabFill={true}> */}
        <input
          type="text"
          placeholder={placeholderValue}
          value={selectedDateRange}
          disabled
          className={[styles.search_input, searchFontXS ? styles.search_font_XS : ''].join(' ')}
          onFocus={(e) => {
            setSearch(e.target.value.trimStart());
            setOnFocusBorderColorChange(true);
          }}
          onBlur={() => {
            setOnFocusBorderColorChange(false);
            setEnterPressed(false);
          }}
        />

        <div className={styles.icon_wrapper} onClick={handleCalendarClose}>
          <div className={styles.search} />
        </div>
      </div>
      {isCalendarOpen && (
        <div className={styles.calendarContainer}>
          <div className={styles.calendarHeader}>
            <div className={styles.calendarHeaderText}>Search Date Range</div>
            <div className={styles.closeIcon} onClick={()=>{setIsCalendarOpen(!isCalendarOpen); setIsDisabled(true)}}></div>
          </div>
          <div className={styles.lineDivider}></div>
          <div className={styles.calendarDescription}>
          Select the start and end dates within a 90 day time period of available learning records.
          </div>
          <DayPicker
            //   className={styles.root}
            classNames={{
              root: `rdp-root ${styles.root}`,
              dropdowns: `rdp-dropdowns ${styles.dropdowns}`,
              months: `rdp-months ${styles.months}`,
              //   nav: `rdp-nav ${styles.nav}`,
              button_previous: `rdp-button_previous ${styles.button_previous}`,
              button_next: `rdp-button_next ${styles.button_next}`,
              weekday: `rdp-weekday ${styles.weekday}`,
              //   day_button:  `rdp-day_button ${styles.day_button}`,
            }}
            modifiersClassNames={{
              range_start: `rdp-range_start ${styles.range_start}`, // Add custom class for range start
              range_end: `rdp-range_end ${styles.range_end}`,
            }}
            captionLayout="dropdown-years"
            mode="range"
            numberOfMonths={1}
            // timeZone="Asia/Calcutta"
            selected={selected}
            onSelect={(range) => {
              console.log("DEBUG range ", range)
              setIsDisabled(false)
              setSelected(range);
              handleSelectedMonth()
            }}
            month={month}
            onMonthChange={handleMonthChange}
            disabled={{
              // before: minDate, // Disable dates before minDate
              after: maxDate,  // Disable dates after maxDate
            }}
          />
          <div className={styles.filter_button_wrapper}>
            <button
              type="button"
              className={[styles.btn, styles.m_lr_6].join(' ')}
              onClick={() => {
                handleCalendarClose()
              }}
            >
              Cancel
            </button>
            <button
              type="button"
              className={[  
                styles.btn, (isDisabled || !selected?.from || !selected?.to) ? styles.btn_disable : '',
                styles.dark,
                styles.m_lr_6,
              ].join(' ')}
              onClick={(e) => {
                formatDateRange(selected);
                onClickApplyCb(selected);
                setIsCalendarOpen(!isCalendarOpen);
                setIsDisabled(true)
              }}
            >
              Apply
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default DateRangerPicker;
