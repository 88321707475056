import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import styles from './SideNavigation.module.scss';
import {useHistory} from 'react-router-dom';
import GlobalNotification from '../globalNotification';
import {
  setCourseMode,
  setDashboardNotificationSwitch,
  setDashboardNotificationCount,
  setNotificationList,
  setSelectedNavigationOption,
  setCurrentUserId,
  setErrorStateTemplate,
  setNotificationErrorState,
  setLearningPath,
} from '../../redux/actions/uiActions';
import {setNewCourseWizardState} from '../../redux/actions/newCourseActions';
import {setMainImageBackup} from '../../redux/actions/imageCrop/imageCropAction';
import {makeRequest} from '../utils/APIsUtils/httpsUtils';
import Strings from '../utils/Localization/Strings';
import { setIsRoasterFilterFormDirty, setNavigateTo } from '../../redux/actions/roaster/roasterListingActions';
import AlertModal from '../shared/alertModal/AlertModal';

const homeIconClick = (
  wizardState,
  setSelectedNavigationOption,
  router,
  setOpenCloseNaviBarMobile,
) => {
  if (!wizardState) {
    setSelectedNavigationOption('DASHBOARD');
    !wizardState && router.push('/Dashboard');
    setOpenCloseNaviBarMobile(false);
  }
};

const notificationPanelOpenClose = (
  wizardState,
  dashboardNotificationSwitch,
  readAllNotifications,
  setDashboardNotificationSwitch,
  setNotificationErrorState,
) => {
  setDashboardNotificationCount(0);
  if (!wizardState) {
    if (dashboardNotificationSwitch) {
      readAllNotifications(setNotificationErrorState);
    }
    setDashboardNotificationSwitch(!dashboardNotificationSwitch);
  }
};

const dashboardNavigation = (
  wizardState,
  setSelectedNavigationOption,
  router,
  setOpenCloseNaviBarMobile,
  openCloseNaviBarMobile,
  setOpenCloseNaviBarIpad,
) => {
  if (!wizardState && openCloseNaviBarMobile) {
    setSelectedNavigationOption('DASHBOARD');
    !wizardState && router.push('/Dashboard');
    setOpenCloseNaviBarMobile(false);
  } else if (!wizardState && !openCloseNaviBarMobile) {
    setOpenCloseNaviBarIpad(false);
    setSelectedNavigationOption('DASHBOARD');
    router.push('/Dashboard');
  }
};

const coursesNavigation = (
  wizardState,
  setSelectedNavigationOption,
  router,
  setOpenCloseNaviBarMobile,
  openCloseNaviBarMobile,
  setNewCourseWizardState,
  setOpenCloseNaviBarIpad,
) => {
  if (!wizardState && openCloseNaviBarMobile) {
    setNewCourseWizardState({
      courseWizardOpen: false,
      courseWizardStateOpen: '',
      section: '',
    });
    setSelectedNavigationOption('COURSES-MANAGEMENT');
    router.push('/coursesManagement');
    setOpenCloseNaviBarMobile(false);
  } else if (!wizardState && !openCloseNaviBarMobile) {
    setOpenCloseNaviBarIpad(false);
    setNewCourseWizardState({
      courseWizardOpen: false,
      courseWizardStateOpen: '',
      section: '',
    });
    setSelectedNavigationOption('COURSES-MANAGEMENT');
    router.push('/coursesManagement');
  }
};

const learningPathNavigation = (
  wizardState,
  setSelectedNavigationOption,
  router,
  setOpenCloseNaviBarMobile,
  openCloseNaviBarMobile,
  setOpenCloseNaviBarIpad,
) => {
  if (!wizardState && openCloseNaviBarMobile) {
    setSelectedNavigationOption('LEARNING-PATH');
    router.push('/LearningPathModal');
    setOpenCloseNaviBarMobile(false);
  } else if (!wizardState && !openCloseNaviBarMobile) {
    setOpenCloseNaviBarIpad(false);
    setSelectedNavigationOption('LEARNING-PATH');
    router.push('/LearningPathModal');
  }
};

const audiencemanagementNavigation = (
  wizardState,
  setSelectedNavigationOption,
  router,
  setOpenCloseNaviBarMobile,
  openCloseNaviBarMobile,
  setOpenCloseNaviBarIpad,
) => {
  if (!wizardState && openCloseNaviBarMobile) {
    setSelectedNavigationOption('AUDIENCE-MANAGEMENT');
    router.push('/UserManagement');
    setOpenCloseNaviBarMobile(false);
  } else if (!wizardState && !openCloseNaviBarMobile) {
    setOpenCloseNaviBarIpad(false);
    setSelectedNavigationOption('AUDIENCE-MANAGEMENT');
    router.push('/UserManagement');
  }
};

const categoriesSkillsTagsManagementNavigation = (
  wizardState,
  setSelectedNavigationOption,
  router,
  setOpenCloseNaviBarMobile,
  openCloseNaviBarMobile,
  setOpenCloseNaviBarIpad,
) => {
  if (!wizardState && openCloseNaviBarMobile) {
    setSelectedNavigationOption('CATEGORY-SKILLS-MANAGEMENT');
    router.push('/CategorySkillsTagsmanagement');
    setOpenCloseNaviBarMobile(false);
  } else if (!wizardState && !openCloseNaviBarMobile) {
    setOpenCloseNaviBarIpad(false);
    setSelectedNavigationOption('CATEGORY-SKILLS-MANAGEMENT');
    router.push('/CategorySkillsTagsmanagement');
  }
};

const contentManagementNavigation = (
  wizardState,
  setSelectedNavigationOption,
  router,
  setOpenCloseNaviBarMobile,
  openCloseNaviBarMobile,
  setOpenCloseNaviBarIpad,
  setLearningPath = () => {}
) => {
  if (!wizardState && openCloseNaviBarMobile) {
    setSelectedNavigationOption('CONTENT-MANAGEMENT');
    setLearningPath('');
    router.push('/Contentmanagement');
    setOpenCloseNaviBarMobile(false);
  } else if (!wizardState && !openCloseNaviBarMobile) {
    setOpenCloseNaviBarIpad(false);
    setSelectedNavigationOption('CONTENT-MANAGEMENT');
    setLearningPath('');
    router.push('/Contentmanagement');
  }
};

const createNewCourseNavigation = (
  wizardState,
  setNewCourseWizardState,
  setCourseMode,
  setMainImageBackup,
  router,
  setOpenCloseNaviBarMobile,
  openCloseNaviBarMobile,
  pageName,
) => {
  if (!wizardState && openCloseNaviBarMobile) {
    setNewCourseWizardState({
      courseWizardOpen: true,
      courseWizardStateOpen: 'main',
      section: pageName,
    });
    setCourseMode('FROM_SIDE_NAVIGATION');
    setMainImageBackup(null);
    // router.push('/coursesManagement');
    const url = '/create-course-wizard';
    //const newWindow = window.open(url + '?id=' + id, '_blank', 'noopener,noreferrer');

    router.push({
      pathname: url,
      search: `?id=0&sub_header=course_description`,
      //query: {actionName: ""},
    });
    setOpenCloseNaviBarMobile(false);
  } else if (!wizardState && !openCloseNaviBarMobile) {
    setNewCourseWizardState({
      courseWizardOpen: true,
      courseWizardStateOpen: 'main',
      section: pageName,
    });
    setCourseMode('FROM_SIDE_NAVIGATION');
    setMainImageBackup(null);
    //router.push('/coursesManagement');
    const url = '/create-course-wizard';
    //const newWindow = window.open(url + '?id=' + id, '_blank', 'noopener,noreferrer');

    router.push({
      pathname: url,
      search: `?id=0&sub_header=course_description`,
      //query: {actionName: ""},
    });
  }
};
const createNewImageNavigation = (
  wizardState,
  setCourseMode,
  router,
  setOpenCloseNaviBarMobile,
  openCloseNaviBarMobile,
) => {
  if (!wizardState && !openCloseNaviBarMobile) {
    setCourseMode('FROM_SIDE_NAVIGATION');
    router.push('/add-new-image');
  } else if (!wizardState) {
    setCourseMode('FROM_SIDE_NAVIGATION');
    router.push('/add-new-image');
    setOpenCloseNaviBarMobile(false);
  }
};

const selectedStateForSideNav = (selectedPageName, pageName) => {
  return pageName === selectedPageName ? styles.selected : '';
};

const ipadDashboardNavigation = (wizardState, router, setOpenCloseNaviBarIpad) => {
  !wizardState && router.push('/Dashboard');
  setOpenCloseNaviBarIpad(false);
};

const ipadCoursesNavigation = (wizardState, setCourseWizard, setOpenCloseNaviBarIpad) => {
  if (!wizardState) {
    setCourseWizard();
    setOpenCloseNaviBarIpad(false);
  }
};
const ipadLearnningPathNavigation = (wizardState, router, setOpenCloseNaviBarIpad) => {
  if (!wizardState) {
    router.push('/LearningPathModal');
    setOpenCloseNaviBarIpad(false);
  }
};
const ipadAudianceManagementNavigation = (wizardState, router, setOpenCloseNaviBarIpad) => {
  !wizardState && router.push('/UserManagement');
  setOpenCloseNaviBarIpad(false);
};
const ipadCSTNavigation = (wizardState, router, setOpenCloseNaviBarIpad) => {
  !wizardState && router.push('/CategorySkillsTagsmanagement');
  setOpenCloseNaviBarIpad(false);
};

const ipadContentManagementNavigation = (wizardState, router, setOpenCloseNaviBarIpad) => {
  !wizardState && router.push('/Contentmanagement');
  setOpenCloseNaviBarIpad(false);
};
const ipadCreateNewCourse = (
  wizardState,
  setNewCourseWizardState,
  setCourseMode,
  setMainImageBackup,
  router,
  setOpenCloseNaviBarIpad,
) => {
  if (!wizardState) {
    setNewCourseWizardState({
      courseWizardOpen: true,
      courseWizardStateOpen: 'main',
      section: '',
    });
    setCourseMode('FROM_SIDE_NAVIGATION');
    setMainImageBackup(null);
    //router.push('/Coursesmanagement');
    const url = '/create-course-wizard';
    //const newWindow = window.open(url + '?id=' + id, '_blank', 'noopener,noreferrer');

    router.push({
      pathname: url,
      search: `?id=0&sub_header=course_description`,
      //query: {actionName: ""},
    });
    setOpenCloseNaviBarIpad(false);
  }
};

const fetchNotificationCountByUserId = (
  currentUserId,
  setDashboardNotificationCount,
  setNotificationList,
) => {
  if (currentUserId) {
    fetchNotificationCount(setDashboardNotificationCount, setNotificationErrorState);
    fetchNotificationList(setNotificationList, setNotificationErrorState);
  }
};

const fetchNotificationCount = async (setDashboardNotificationCount, setNotificationErrorState) => {
  const currentUserAmwayId = window.localStorage.getItem('am_id');
  const dataNotificationCount = await makeRequest({
    method: 'GET',
    endpoint: `/admin/api/v1/users/${currentUserAmwayId}/notifications/count`,
  });
  if (dataNotificationCount?.data) {
    setDashboardNotificationCount(dataNotificationCount?.data?.count);
    setNotificationErrorState(false);
  } else if (dataNotificationCount == 'ERR_NETWORK') {
    setNotificationErrorState(true);
  }
};

const fetchNotificationList = async (setNotificationList, setNotificationErrorState) => {
  const currentUserAmwayId = window.localStorage.getItem('am_id');
  const dataNotifications = await makeRequest({
    method: 'GET',
    endpoint: `/admin/api/v1/users/${currentUserAmwayId}/notifications`,
  });
  if (dataNotifications?.data) {
    setNotificationList(dataNotifications?.data);
    setNotificationErrorState(false);
  } else if (dataNotifications == 'ERR_NETWORK') {
    setNotificationErrorState(true);
  }
};

const SideNavigationComponent = (props) => {
  const {
    setCourseMode,
    setDashboardNotificationSwitch,
    dashboardNotificationSwitch,
    dashboardNotificationCount,
    setDashboardNotificationCount,
    setNotificationList,
    navigationDisable,
    setNewCourseWizardState,
    setMainImageBackup,
    pageName,
    setSelectedNavigationOption,
    currentUserId,
    setErrorStateTemplate,
    setNotificationErrorState,
    setLearningPath,
    isRoasterFilterFormDirty,
    setIsRoasterFilterFormDirty,
    navigateTo,
    setNavigateTo,
  } = props;
  const router = useHistory();
  const [isOnline, setOnline] = useState(true);
  const [isAlertModalopen, setIsAlertModalOpen] = useState(false)
  const [isIpadNavigationClicked, setIsIpadNavigationClicked] = useState(false)

  const [width, setWidth] = useState(0);
  const updateWindowDimensions = () => {
    setWidth(window.innerWidth);
  };
  window.addEventListener('online', () => {
    setOnline(true);
    window.location.reload();
  });
  window.addEventListener('offline', () => {
    setDashboardNotificationSwitch(false);
    setOnline(false);
  });
  useEffect(() => {
    // if (currentUserId) {
    //   fetchNotificationCount();
    //   fetchNotificationList();
    // }else{
    //   setCurrentUserId(window.localStorage.getItem("am_id"))
    // }
    setOnline(navigator.onLine);
    !navigator.onLine && setDashboardNotificationSwitch(false);
    currentUserId
      ? (fetchNotificationCount(setDashboardNotificationCount, setNotificationErrorState),
        fetchNotificationList(setNotificationList, setNotificationErrorState))
      : setCurrentUserId(window.localStorage.getItem('am_id'));
    window.addEventListener('resize', updateWindowDimensions);

    return () => {
      window.removeEventListener('resize', updateWindowDimensions);
    };
  }, []);

  useEffect(() => {
    fetchNotificationCountByUserId(
      currentUserId,
      setDashboardNotificationCount,
      setNotificationList,
    );
  }, [currentUserId]);

  const [wizardState, setWizardState] = useState(null);
  const [openCloseNaviBarIpad, setOpenCloseNaviBarIpad] = useState(false);
  const [openCloseNaviBarMobile, setOpenCloseNaviBarMobile] = useState(false);
  const [mobileDropDown, setMobileDropDown] = useState(true);

  // const fetchNotificationCount = async () => {
  //   const currentUserAmwayId = window.localStorage.getItem("am_id");
  //   const dataNotificationCount = await makeRequest({
  //     method: "GET",
  //     endpoint: `/admin/api/v1/users/${currentUserAmwayId}/notifications/count`
  //   })
  //   if(dataNotificationCount?.data) {
  //     setDashboardNotificationCount(dataNotificationCount?.data?.count);
  //   }
  // };

  // const fetchNotificationList = async () => {
  //   const currentUserAmwayId = window.localStorage.getItem("am_id");
  //   const dataNotifications = await makeRequest({
  //     method: "GET",
  //     endpoint: `/admin/api/v1/users/${currentUserAmwayId}/notifications`
  //   })
  //   if(dataNotifications?.data) {
  //     setNotificationList(dataNotifications?.data);
  //   }
  // };

  const getNavigateToDetails = (route) => {
    switch (route) {
      case '/Dashboard':
        return ()=>{
          dashboardNavigation(
            wizardState,
            setSelectedNavigationOption,
            router,
            setOpenCloseNaviBarMobile,
            openCloseNaviBarMobile,
            setOpenCloseNaviBarIpad,
          );
        }
      case '/coursesManagement':
        return ()=>{
          coursesNavigation(
            wizardState,
            setSelectedNavigationOption,
            router,
            setOpenCloseNaviBarMobile,
            openCloseNaviBarMobile,
            setNewCourseWizardState,
            setOpenCloseNaviBarIpad,
          );
        }
      case '/LearningPathModal':
        return ()=>{
          learningPathNavigation(
            wizardState,
            setSelectedNavigationOption,
            router,
            setOpenCloseNaviBarMobile,
            openCloseNaviBarMobile,
            setOpenCloseNaviBarIpad,
          );
        }
      case '/UserManagement':
        return ()=>{
          audiencemanagementNavigation(
            wizardState,
            setSelectedNavigationOption,
            router,
            setOpenCloseNaviBarMobile,
            openCloseNaviBarMobile,
            setOpenCloseNaviBarIpad,
          );
        }
      case '/Contentmanagement':
        return ()=>{
          contentManagementNavigation(
            wizardState,
            setSelectedNavigationOption,
            router,
            setOpenCloseNaviBarMobile,
            openCloseNaviBarMobile,
            setOpenCloseNaviBarIpad,
            setLearningPath
          );
        }
      case '/CategorySkillsTagsmanagement':
        return ()=>{
          categoriesSkillsTagsManagementNavigation(
            wizardState,
            setSelectedNavigationOption,
            router,
            setOpenCloseNaviBarMobile,
            openCloseNaviBarMobile,
            setOpenCloseNaviBarIpad,
          );
        }
      case '/create-course-wizard':
        return ()=>{
          createNewCourseNavigation(
            wizardState,
            setNewCourseWizardState,
            setCourseMode,
            setMainImageBackup,
            router,
            setOpenCloseNaviBarMobile,
            openCloseNaviBarMobile,
            pageName,
          );
        }
      case '/add-new-image':
        return ()=>{
          createNewImageNavigation(
            wizardState,
            setCourseMode,
            router,
            setOpenCloseNaviBarMobile,
            openCloseNaviBarMobile,
          );
        }
    }
  }

  const getIpadNavigateToDetails = (route) => {
    switch (route) {
      case '/Dashboard':
        return ()=>{
          ipadDashboardNavigation(wizardState, router, setOpenCloseNaviBarIpad);
        }
      case '/coursesManagement':
        return ()=>{
          ipadCoursesNavigation(wizardState, setCourseWizard, setOpenCloseNaviBarIpad);
        }
      case '/LearningPathModal':
        return ()=>{
          ipadLearnningPathNavigation(wizardState, router, setOpenCloseNaviBarIpad);
        }
      case '/UserManagement':
        return ()=>{
          ipadAudianceManagementNavigation(wizardState, router, setOpenCloseNaviBarIpad);
        }
      case '/Contentmanagement':
        return ()=>{
          ipadContentManagementNavigation(wizardState, router, setOpenCloseNaviBarIpad);
        }
      case '/CategorySkillsTagsmanagement':
        return ()=>{
          ipadCSTNavigation(wizardState, router, setOpenCloseNaviBarIpad);
        }
      case '/create-course-wizard':
        return ()=>{
          ipadCreateNewCourse(
            wizardState,
            setNewCourseWizardState,
            setCourseMode,
            setMainImageBackup,
            router,
            setOpenCloseNaviBarIpad,
          );
        }
    }
  }

  const readAllNotifications = async () => {
    const currentUserAmwayId = window.localStorage.getItem('am_id');
    const dataNotifications = await makeRequest({
      method: 'PUT',
      endpoint: `/admin/api/v1/users/${currentUserAmwayId}/notifications`,
    });
    if (dataNotifications) {
      fetchNotificationList(setNotificationList, setNotificationErrorState);
      fetchNotificationCount(setDashboardNotificationCount, setNotificationErrorState);
    } else if (dataNotifications == 'ERR_NETWORK') {
      setNotificationErrorState(true);
    }
  };

  const setCourseWizard = () => {
    setNewCourseWizardState({
      courseWizardOpen: false,
      courseWizardStateOpen: '',
      section: '',
    });
    setCourseMode('FROM_SIDE_NAVIGATION');
    setMainImageBackup(null);
    //router.push('/Coursesmanagement');
    const url = '/create-course-wizard';
    //const newWindow = window.open(url + '?id=' + id, '_blank', 'noopener,noreferrer');

    router.push({
      pathname: url,
      search: `?id=0&sub_header=course_description`,
      //query: {actionName: ""},
    });
  };
  useEffect(() => {
    setWizardState(navigationDisable);
  }, [navigationDisable]);
  return (
    <React.Fragment>
      <div
        className={[
          styles.sidenav_container,
          styles.sm_sidenav_container,
          openCloseNaviBarIpad ? styles.ipad_view_container : '',
        ].join(' ')}
      >
        <div className={styles.sidenav_wrapper}>
          <div className={styles.icon_wrapper}>
            <div
              className={styles.logo}
              onClick={() => {
                if(isRoasterFilterFormDirty){
                  setIsAlertModalOpen(true)
                  setNavigateTo('/Dashboard')
                  setIsIpadNavigationClicked(false)
                } else {
                  isOnline &&
                  setErrorStateTemplate({
                    variant: '',
                    status: false,
                  });
                  homeIconClick(
                    wizardState,
                    setSelectedNavigationOption,
                    router,
                    setOpenCloseNaviBarMobile,
                  );
                }
              }}
            />
          </div>

          {(width > 575 || (width <= 575 && !openCloseNaviBarMobile)) && (
            <div
              className={[styles.icon_wrapper, styles.notification_wrapper].join(' ')}
              onClick={() => {
                isOnline &&
                  setErrorStateTemplate({
                    variant: '',
                    status: false,
                  });
                notificationPanelOpenClose(
                  wizardState,
                  dashboardNotificationSwitch,
                  readAllNotifications,
                  setDashboardNotificationSwitch,
                  setNotificationErrorState,
                );
              }}
            >
              <div
                // Add here "selected" class for bell icon, scss added.
                className={[
                  styles.notification,
                  dashboardNotificationSwitch ? styles.selected : null,
                ].join(' ')}
              />

              {/* {dashboardNotificationCount > 0 && (
              <div
                // Add here "selected" class for bell icon, scss added.
                className={styles.notification}
                onClick={() => {
                  if (!wizardState && notificationList?.today !== undefined) {
                    if (dashboardNotificationSwitch) {
                      readAllNotifications();
                    }
                    setDashboardNotificationSwitch(
                      !dashboardNotificationSwitch
                    );
                  }
                }}
              />
            )} */}
              {dashboardNotificationCount > 0 && !dashboardNotificationSwitch && (
                <div
                  className={styles.notification_counter}
                  onClick={() => {
                    isOnline &&
                      setErrorStateTemplate({
                        variant: '',
                        status: false,
                      });
                    notificationPanelOpenClose(
                      wizardState,
                      dashboardNotificationSwitch,
                      readAllNotifications,
                      setDashboardNotificationSwitch,
                      setNotificationErrorState,
                    );
                  }}
                >
                  {dashboardNotificationCount}
                </div>
              )}
            </div>
          )}
          <div
            // Add class 'on_click_hamb' with below class for cross icon insted hamburger
            className={[
              styles.icon_wrapper,
              styles.hamburger_wrapper,
              openCloseNaviBarMobile ? styles.on_click_hamb : '',
            ].join(' ')}
          >
            <div
              className={styles.hamburger}
              onClick={() => setOpenCloseNaviBarMobile(!openCloseNaviBarMobile)}
            />
            {width <= 575 && openCloseNaviBarMobile && (
              <div className={[styles.hb_inner_wrapper].join(' ')}>
                <div className={styles.navlist}>
                  <div
                    className={styles.item}
                    onClick={() => {
                      if(isRoasterFilterFormDirty){
                        setIsAlertModalOpen(true)
                        setNavigateTo('/Dashboard')
                        setIsIpadNavigationClicked(false)
                      } else {
                        isOnline &&
                        setErrorStateTemplate({
                          variant: '',
                          status: false,
                        });
                        dashboardNavigation(
                          wizardState,
                          setSelectedNavigationOption,
                          router,
                          setOpenCloseNaviBarMobile,
                          openCloseNaviBarMobile,
                          setOpenCloseNaviBarIpad,
                        );
                      }
                    }}
                  >
                    Dashboard
                  </div>
                  <div
                    className={styles.item}
                    onClick={() => {
                      if(isRoasterFilterFormDirty){
                        setIsAlertModalOpen(true)
                        setNavigateTo('/coursesManagement')
                        setIsIpadNavigationClicked(false)
                      } else {
                        isOnline &&
                        setErrorStateTemplate({
                          variant: '',
                          status: false,
                        });
                        coursesNavigation(
                          wizardState,
                          setSelectedNavigationOption,
                          router,
                          setOpenCloseNaviBarMobile,
                          openCloseNaviBarMobile,
                          setNewCourseWizardState,
                          setOpenCloseNaviBarIpad,
                        );
                      }
                    }}
                  >
                    Course Management
                  </div>
                  <div
                    className={[styles.item, styles.learningPathText].join(' ')}
                    onClick={() => {
                      if(isRoasterFilterFormDirty){
                        setIsAlertModalOpen(true)
                        setNavigateTo('/LearningPathModal')
                        setIsIpadNavigationClicked(false)
                      } else {
                        isOnline &&
                        setErrorStateTemplate({
                          variant: '',
                          status: false,
                        });
                        learningPathNavigation(
                          wizardState,
                          setSelectedNavigationOption,
                          router,
                          setOpenCloseNaviBarMobile,
                          openCloseNaviBarMobile,
                          setOpenCloseNaviBarIpad,
                        );
                      }
                    }}
                  >
                    Learning Path Management
                  </div>
                  <div
                    className={styles.item}
                    onClick={() => {
                      if(isRoasterFilterFormDirty){
                        setIsAlertModalOpen(true)
                        setNavigateTo('/LearningPathModal')
                        setIsIpadNavigationClicked(false)
                      } else {
                        isOnline &&
                        setErrorStateTemplate({
                          variant: '',
                          status: false,
                        });
                        audiencemanagementNavigation(
                          wizardState,
                          setSelectedNavigationOption,
                          router,
                          setOpenCloseNaviBarMobile,
                          openCloseNaviBarMobile,
                          setOpenCloseNaviBarIpad,
                        );
                      }
                    }}
                  >
                    User Group Management
                  </div>
                  <div
                    className={styles.item}
                    onClick={() => {
                      if(isRoasterFilterFormDirty){
                        setIsAlertModalOpen(true)
                        setNavigateTo('/Contentmanagement')
                        setIsIpadNavigationClicked(false)
                      } else {
                        isOnline &&
                        setErrorStateTemplate({
                          variant: '',
                          status: false,
                        });
                        contentManagementNavigation(
                          wizardState,
                          setSelectedNavigationOption,
                          router,
                          setOpenCloseNaviBarMobile,
                          openCloseNaviBarMobile,
                          setOpenCloseNaviBarIpad,
                          setLearningPath
                        );
                      }
                    }}
                  >
                    {Strings.IMG_LIBRARY}
                  </div>
                  <div
                    className={styles.item}
                    onClick={() => {
                      if(isRoasterFilterFormDirty){
                        setIsAlertModalOpen(true)
                        setNavigateTo('/CategorySkillsTagsmanagement')
                        setIsIpadNavigationClicked(false)
                      } else {
                        isOnline &&
                        setErrorStateTemplate({
                          variant: '',
                          status: false,
                        });
                        categoriesSkillsTagsManagementNavigation(
                          wizardState,
                          setSelectedNavigationOption,
                          router,
                          setOpenCloseNaviBarMobile,
                          openCloseNaviBarMobile,
                          setOpenCloseNaviBarIpad,
                        );
                      }
                    }}
                  >
                    Categories, Skills, Tags Management
                  </div>
                  <div
                    className={[
                      styles.item,
                      styles.item_wrapper,
                      mobileDropDown ? styles.open_list : '',
                    ].join(' ')}
                    onClick={() => setMobileDropDown(!mobileDropDown)}
                  >
                    <div className={styles.nav_wraper}>
                      <div className={styles.item}>Create New</div>
                      <div className={[styles.arrow_icon].join(' ')}></div>
                    </div>
                    <div className={styles.list_wrapper}>
                      <div
                        className={styles.list_item}
                        onClick={() => {
                          if(isRoasterFilterFormDirty){
                            setIsAlertModalOpen(true)
                            setNavigateTo('/create-course-wizard')
                            setIsIpadNavigationClicked(false)
                          } else {
                            isOnline &&
                            setErrorStateTemplate({
                              variant: '',
                              status: false,
                            });
                            createNewCourseNavigation(
                              wizardState,
                              setNewCourseWizardState,
                              setCourseMode,
                              setMainImageBackup,
                              router,
                              setOpenCloseNaviBarMobile,
                              openCloseNaviBarMobile,
                              pageName,
                            );
                          }
                        }}
                      >
                        Course Management
                      </div>
                      <div
                        className={styles.list_item}
                        onClick={() => {
                          if(isRoasterFilterFormDirty){
                            setIsAlertModalOpen(true)
                            setNavigateTo('/add-new-image')
                            setIsIpadNavigationClicked(false)
                          } else {
                            isOnline &&
                            setErrorStateTemplate({
                              variant: '',
                              status: false,
                            });
                            createNewImageNavigation(
                              wizardState,
                              setCourseMode,
                              router,
                              setOpenCloseNaviBarMobile,
                              openCloseNaviBarMobile,
                            );
                          }
                        }}
                      >
                        Image Asset
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className={styles.nav_items}>
            <div
              className={[
                styles.item_wrapper,
                selectedStateForSideNav('DASHBOARD', pageName),
                styles.home_hover,
                '',
              ].join(' ')}
              onClick={() => {
                if(isRoasterFilterFormDirty){
                  setIsAlertModalOpen(true)
                  setNavigateTo('/Dashboard')
                  setIsIpadNavigationClicked(false)
                } else {
                  isOnline &&
                  setErrorStateTemplate({
                    variant: '',
                    status: false,
                  });
                  dashboardNavigation(
                    wizardState,
                    setSelectedNavigationOption,
                    router,
                    setOpenCloseNaviBarMobile,
                    openCloseNaviBarMobile,
                    setOpenCloseNaviBarIpad,
                  );
                }
              }}
            >
              <div className={[styles.item, styles.home, ''].join(' ')} />

              <div className={[styles.hover_state].join(' ')}>
                <div className={[styles.icon_wrapper_sidenav, styles.home_blue_icon].join(' ')} />
                <div className={styles.menu_txt}>Dashboard </div>
              </div>
            </div>

            <div
              className={[
                styles.item_wrapper,
                selectedStateForSideNav('COURSES-MANAGEMENT', pageName),
                styles.files_hover,
              ].join(' ')}
              onClick={() => {
                if(isRoasterFilterFormDirty){
                  setIsAlertModalOpen(true)
                  setNavigateTo('/coursesManagement')
                  setIsIpadNavigationClicked(false)
                } else {
                  isOnline &&
                  setErrorStateTemplate({
                    variant: '',
                    status: false,
                  });
                  coursesNavigation(
                    wizardState,
                    setSelectedNavigationOption,
                    router,
                    setOpenCloseNaviBarMobile,
                    openCloseNaviBarMobile,
                    setNewCourseWizardState,
                    setOpenCloseNaviBarIpad,
                  );
                }
              }}
            >
              <div className={[styles.hover_state].join(' ')}>
                <div className={[styles.icon_wrapper_sidenav, styles.book_blue_icon].join(' ')} />
                <div className={styles.menu_txt}>Course Management</div>
              </div>
              <div className={[styles.item, styles.files].join(' ')} />
            </div>

            <div
              className={[
                styles.item_wrapper,
                selectedStateForSideNav('LEARNING-PATH', pageName),
                styles.folder_hover,
              ].join(' ')}
              onClick={() => {
                if(isRoasterFilterFormDirty){
                  setIsAlertModalOpen(true)
                  setNavigateTo('/LearningPathModal')
                  setIsIpadNavigationClicked(false)
                } else {
                  isOnline &&
                  setErrorStateTemplate({
                    variant: '',
                    status: false,
                  });
                  learningPathNavigation(
                    wizardState,
                    setSelectedNavigationOption,
                    router,
                    setOpenCloseNaviBarMobile,
                    openCloseNaviBarMobile,
                    setOpenCloseNaviBarIpad,
                  );
                }
              }}
            >
              <div className={[styles.hover_state].join(' ')}>
                <div className={[styles.icon_wrapper_sidenav, styles.folder_blue_icon].join(' ')} />
                <div className={styles.menu_txt}>Learning Path Management </div>
              </div>
              <div className={[styles.item, styles.folder].join(' ')} />
            </div>
            <div
              className={[
                styles.item_wrapper,
                selectedStateForSideNav('AUDIENCE-MANAGEMENT', pageName),
                styles.people_hover,
              ].join(' ')}
              onClick={() => {
                if(isRoasterFilterFormDirty){
                  setIsAlertModalOpen(true)
                  setNavigateTo('/UserManagement')
                  setIsIpadNavigationClicked(false)
                } else {
                  isOnline &&
                  setErrorStateTemplate({
                    variant: '',
                    status: false,
                  });
                  audiencemanagementNavigation(
                    wizardState,
                    setSelectedNavigationOption,
                    router,
                    setOpenCloseNaviBarMobile,
                    openCloseNaviBarMobile,
                    setOpenCloseNaviBarIpad,
                  );
                }
              }}
            >
              <div className={[styles.hover_state].join(' ')}>
                <div className={[styles.icon_wrapper_sidenav, styles.user_blue_icon].join(' ')} />
                <div className={styles.menu_txt}>User Group Management</div>
              </div>
              <div className={[styles.item, styles.peoples].join(' ')} />
            </div>
            <div
              className={[
                styles.item_wrapper,
                selectedStateForSideNav('CONTENT-MANAGEMENT', pageName),
                styles.image_hover,
              ].join(' ')}
              onClick={() => {
                if(isRoasterFilterFormDirty){
                  setIsAlertModalOpen(true)
                  setNavigateTo('/Contentmanagement')
                  setIsIpadNavigationClicked(false)
                } else {
                  isOnline &&
                  setErrorStateTemplate({
                    variant: '',
                    status: false,
                  });
                  contentManagementNavigation(
                    wizardState,
                    setSelectedNavigationOption,
                    router,
                    setOpenCloseNaviBarMobile,
                    openCloseNaviBarMobile,
                    setOpenCloseNaviBarIpad,
                    setLearningPath
                  );
                }
              }}
            >
              <div className={[styles.hover_state].join(' ')}>
                <div className={[styles.icon_wrapper_sidenav, styles.image_blue_icon].join(' ')} />
                <div className={styles.menu_txt}>{Strings.IMG_LIBRARY}</div>
              </div>
              <div className={[styles.item, styles.img_gallery].join(' ')} />
            </div>

            <div
              className={[
                styles.item_wrapper,
                selectedStateForSideNav('CATEGORY-SKILLS-MANAGEMENT', pageName),
                styles.home_hover,
                '',
              ].join(' ')}
              onClick={() => {
                if(isRoasterFilterFormDirty){
                  setIsAlertModalOpen(true)
                  setNavigateTo('/CategorySkillsTagsmanagement')
                  setIsIpadNavigationClicked(false)
                } else {
                  isOnline &&
                  setErrorStateTemplate({
                    variant: '',
                    status: false,
                  });
                  categoriesSkillsTagsManagementNavigation(
                    wizardState,
                    setSelectedNavigationOption,
                    router,
                    setOpenCloseNaviBarMobile,
                    openCloseNaviBarMobile,
                    setOpenCloseNaviBarIpad,
                  );
                }
              }}
            >
              <div className={[styles.hover_state].join(' ')}>
                <div
                  className={[styles.icon_wrapper_sidenav, styles.category_skills_blue_icon].join(
                    ' ',
                  )}
                />
                <div className={styles.menu_txt}>Categories, Skills, Tags Management</div>
              </div>
              <div className={[styles.item, styles.category_skills, ''].join(' ')} />
            </div>

            <div className={[styles.item_wrapper, styles.plusicon_hover].join(' ')}>
              <div className={[styles.item, styles.plus].join(' ')}>
                <div className={styles.bottom_main_wrapper}>
                  <div className={[styles.bottom_icon_wrapper].join(' ')}>
                    <div
                      className={[
                        styles.item,
                        styles.icon_wrapper_sidenav,
                        styles.plus_blue_icon,
                      ].join(' ')}
                    />
                  </div>
                  <div className={styles.menu_wrapper}>
                    <div
                      className={[styles.menu_txt, styles.width, styles.submenu_text].join(' ')}
                      onClick={() => {
                        if(isRoasterFilterFormDirty){
                          setIsAlertModalOpen(true)
                          setNavigateTo('/create-course-wizard')
                          setIsIpadNavigationClicked(false)
                        } else {
                          isOnline &&
                          setErrorStateTemplate({
                            variant: '',
                            status: false,
                          });
                          createNewCourseNavigation(
                            wizardState,
                            setNewCourseWizardState,
                            setCourseMode,
                            setMainImageBackup,
                            router,
                            setOpenCloseNaviBarMobile,
                            openCloseNaviBarMobile,
                            pageName,
                          );
                        }
                      }}
                    >
                      New Course
                    </div>
                    <div
                      className={[styles.submenu_text].join(' ')}
                      onClick={() => {
                        if(isRoasterFilterFormDirty){
                          setIsAlertModalOpen(true)
                          setNavigateTo('/add-new-image')
                          setIsIpadNavigationClicked(false)
                        } else {
                          isOnline &&
                          setErrorStateTemplate({
                            variant: '',
                            status: false,
                          });
                          createNewImageNavigation(
                            wizardState,
                            setCourseMode,
                            router,
                            setOpenCloseNaviBarMobile,
                            openCloseNaviBarMobile,
                          );
                        }
                      }}
                    >
                      {' '}
                      New Image Asset
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className={[styles.icon_wrapper, styles.settings_wrapper].join(" ")}>
            <div className={styles.settings} />
          </div> */}
          <div className={[styles.icon_wrapper, styles.toggle_arrow_wrapper].join(' ')}>
            <div
              className={styles.toggle_inner_wrapper}
              onClick={() => {
                isOnline &&
                  setErrorStateTemplate({
                    variant: '',
                    status: false,
                  });
                setOpenCloseNaviBarIpad(!openCloseNaviBarIpad);
              }}
            >
              <div className={styles.toggle_blue} />
            </div>
          </div>
        </div>
        <div className={styles.ipad_view_wrapper}>
          <div className={styles.navlist}>
            <div className={styles.item}>Notifications</div>
            <div
              className={styles.item}
              onClick={() => {
                if(isRoasterFilterFormDirty){
                  setIsAlertModalOpen(true)
                  setNavigateTo('/Dashboard')
                  setIsIpadNavigationClicked(true)
                } else {
                  isOnline &&
                  setErrorStateTemplate({
                    variant: '',
                    status: false,
                  });
                  ipadDashboardNavigation(wizardState, router, setOpenCloseNaviBarIpad);
                }
              }}
            >
              Dashboard
            </div>
            <div
              className={styles.item}
              onClick={() => {
                if(isRoasterFilterFormDirty){
                  setIsAlertModalOpen(true)
                  setNavigateTo('/coursesManagement')
                  setIsIpadNavigationClicked(true)
                } else {
                  isOnline &&
                  setErrorStateTemplate({
                    variant: '',
                    status: false,
                  });
                  ipadCoursesNavigation(wizardState, setCourseWizard, setOpenCloseNaviBarIpad);
                }
              }}
            >
              Course Management
            </div>
            <div
              className={styles.item}
              onClick={() => {
                if(isRoasterFilterFormDirty){
                  setIsAlertModalOpen(true)
                  setNavigateTo('/LearningPathModal')
                  setIsIpadNavigationClicked(true)
                } else {
                  isOnline &&
                  setErrorStateTemplate({
                    variant: '',
                    status: false,
                  });
                  ipadLearnningPathNavigation(wizardState, router, setOpenCloseNaviBarIpad);
                }
              }}
            >
              Learning Path Management
            </div>
            <div
              className={styles.item}
              onClick={() => {
                if(isRoasterFilterFormDirty){
                  setIsAlertModalOpen(true)
                  setNavigateTo('/UserManagement')
                  setIsIpadNavigationClicked(true)
                } else {
                  isOnline &&
                  setErrorStateTemplate({
                    variant: '',
                    status: false,
                  });
                  ipadAudianceManagementNavigation(wizardState, router, setOpenCloseNaviBarIpad);
                }
              }}
            >
              User Group Management
            </div>

            <div
              className={styles.item}
              onClick={() => {
                if(isRoasterFilterFormDirty){
                  setIsAlertModalOpen(true)
                  setNavigateTo('/Contentmanagement')
                  setIsIpadNavigationClicked(true)
                } else {
                  isOnline &&
                  setErrorStateTemplate({
                    variant: '',
                    status: false,
                  });
                  ipadContentManagementNavigation(wizardState, router, setOpenCloseNaviBarIpad);
                }
              }}
            >
              {Strings.IMG_LIBRARY}
            </div>

            <div
              className={[styles.item, styles.ipad_cst].join(' ')}
              onClick={() => {
                if(isRoasterFilterFormDirty){
                  setIsAlertModalOpen(true)
                  setNavigateTo('/CategorySkillsTagsmanagement')
                  setIsIpadNavigationClicked(true)
                } else {
                  isOnline &&
                  setErrorStateTemplate({
                    variant: '',
                    status: false,
                  });
                  ipadCSTNavigation(wizardState, router, setOpenCloseNaviBarIpad);
                }
              }}
            >
              Categories, Skills, Tags Management
            </div>

            <div className={[styles.item, styles.item_wrapper, styles.open_list].join(' ')}>
              <div className={styles.nav_wraper}>
                <div className={[styles.item, styles.zero_margin_class].join(' ')}>Create New</div>
                <div className={[styles.arrow_icon].join(' ')}></div>
              </div>
              <div className={styles.list_wrapper}>
                <div
                  className={styles.list_item}
                  onClick={() => {
                    if(isRoasterFilterFormDirty){
                      setIsAlertModalOpen(true)
                      setNavigateTo('/create-course-wizard')
                      setIsIpadNavigationClicked(true)
                    } else {
                      isOnline &&
                      setErrorStateTemplate({
                        variant: '',
                        status: false,
                      });
                      ipadCreateNewCourse(
                        wizardState,
                        setNewCourseWizardState,
                        setCourseMode,
                        setMainImageBackup,
                        router,
                        setOpenCloseNaviBarIpad,
                      );
                    }
                  }}
                >
                  Courses
                </div>
                <div className={styles.list_item}>Image Asset</div>
                {/* <div className={styles.list_item}>Learning Path</div>
                <div className={styles.list_item}>Audience Group</div>
                <div className={styles.list_item}>Add New Content</div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {dashboardNotificationSwitch && <GlobalNotification />}
      {isAlertModalopen && <AlertModal 
          onClose={()=>setIsAlertModalOpen(false)} 
          onExit={()=>{
            const routeTo = isIpadNavigationClicked ? getIpadNavigateToDetails(navigateTo) : getNavigateToDetails(navigateTo)
            setIsRoasterFilterFormDirty(false)
            setIsAlertModalOpen(false)
            isOnline && setErrorStateTemplate({variant: '',status: false,});
            routeTo()
          }
        }
        />}
    </React.Fragment>
  );
};

// SideNavigationComponent.propTypes = {};
const mapStateToProps = (state) => ({
  dashboardNotificationSwitch: state?.uiState?.dashboardNotificationSwitch,
  dashboardNotificationCount: state?.uiState?.dashboardNotificationCount,
  navigationDisable: state && state.courseState && state.courseState.navigationDisable,
  notificationList: state?.uiState?.notificationList,
  pageName: state && state.uiState && state.uiState.pageName,
  currentUserId: state && state.uiState && state.uiState.currentUserId,
  isRoasterFilterFormDirty : state && state.roasterListing.isRoasterFilterFormDirty,
  navigateTo: state && state.roasterListing.navigateTo,
});

const mapDispatchToProps = (dispatch) => ({
  setCourseMode: (options) => dispatch(setCourseMode(options)),
  setDashboardNotificationSwitch: (options) => dispatch(setDashboardNotificationSwitch(options)),
  setDashboardNotificationCount: (options) => dispatch(setDashboardNotificationCount(options)),
  setNotificationList: (options) => dispatch(setNotificationList(options)),
  setNewCourseWizardState: (options) => dispatch(setNewCourseWizardState(options)),
  setMainImageBackup: (options) => dispatch(setMainImageBackup(options)),
  setSelectedNavigationOption: (options) => dispatch(setSelectedNavigationOption(options)),
  setCurrentUserId: (options) => dispatch(setCurrentUserId(options)),
  setErrorStateTemplate: (options) => dispatch(setErrorStateTemplate(options)),
  setNotificationErrorState: (options) => dispatch(setNotificationErrorState(options)),
  setLearningPath: (options) => dispatch(setLearningPath(options)),
  setIsRoasterFilterFormDirty: (options) => dispatch(setIsRoasterFilterFormDirty(options)),
  setNavigateTo: (options) => dispatch(setNavigateTo(options)),
});

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(SideNavigationComponent));
