import React from 'react';
import AboListing from '../components/roasterAdmin/aboListing';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

const RoasterAdminPage = () => {

  const admin_roster = useSelector(state => state?.uiState?.remoteConfig?.admin_roster || false);
  const history = useHistory();

  if(!admin_roster) {
    history.push('/Dashboard');
  }

  return (
    <div className="page_content">
      <AboListing/>
    </div>
  );
};

export default RoasterAdminPage;
