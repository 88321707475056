import axios from 'axios';
import config from '../../../config';
const axiosInstance = axios.create();
const {currentRegion, currentEnv} = config;
const CURRENTENV = currentEnv.toLowerCase();
const {apiConfig, oidc} = config[currentRegion];
const BASE_URL = apiConfig[CURRENTENV].baseURL;

export const getCurrentRegionOIDCConfigs = () => oidc[CURRENTENV];
export const getCurrentRegionAPIConfigs = () => apiConfig[CURRENTENV];

export const checkTokenValid = (expTime) => {
  return Date.now() / 1000 > expTime;
};

export default function clearAllCookies(names) {
  if (window) {
    names.forEach((name) => {
      document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/';
    });
  }
}

export const _apiHeaderConfigs = () => {
  const _localStorageOktaTokenStorage = JSON.parse(localStorage.getItem('okta-token-storage'));
  if (_localStorageOktaTokenStorage) {
    const {idToken = {}, accessToken = {}} = _localStorageOktaTokenStorage;
    const _accessToken = accessToken && accessToken.accessToken;
    const _idToken = idToken && idToken.idToken;

    if (checkTokenValid(accessToken?.expiresAt)) {
      localStorage.removeItem('okta-token-storage');
      clearAllCookies(['oktaStateToken']);
      window.location.href = '/login';
      return {
        _accessToken: false,
        _idToken: false,
      };
    } else {
      return {
        _accessToken: _accessToken,
        _idToken: _idToken,
      };
    }
  } else {
    localStorage.removeItem('okta-token-storage');
    clearAllCookies(['oktaStateToken']);
    window.location.href = '/login';
    return {
      _accessToken: false,
      _idToken: false,
    };
  }
};

export const makeRequest = async (params) => {
  const {method} = params;
  let resp = {};
  switch (method) {
    case 'GET':
      resp = await makeGetRequest(params);
      return resp;
    case 'POST':
      resp = await makePostRequest(params);
      return resp;
    case 'PUT':
      resp = await makePutRequest(params);
      return resp;
    case 'DELETE':
      resp = await makeDeleteRequest(params);
      return resp;
    case 'PATCH':
      resp = await makePatchRequest(params);
      return resp;
    default:
      console.warn('[httpUtil] >> [makeRequest] >> Please provide valid HTTP method.');
  }
};

const makeGetRequest = async (params) => {
  const {endpoint, url} = params;
  const _headers = _apiHeaderConfigs();
  const resp = await axiosInstance
    .get(url ? url : BASE_URL + endpoint, {
      headers: {
        'Content-Type': 'application/json',
        authorization: _headers._accessToken,
        profile: _headers._idToken,
      },
    })
    .then((response) => {
      const {url} = params;
      if (url) {
        return response;
      } else {
        const {status} = response;
        if (status === 200) {
          return response && response.data ? response.data : [];
        } else if (status === 500 || status >= 500) {
          return 'ERR_NETWORK';
        }
      }
    })
    .catch((err) => {
      return err.code && 'ERR_NETWORK';
    });
  return resp;
};

const makePostRequest = async (params) => {
  const {endpoint, body} = params;
  const _headers = _apiHeaderConfigs();
  const resp = await axiosInstance
    .post(BASE_URL + endpoint, body, {
      headers: {
        'Content-Type': 'application/json',
        authorization: _headers._accessToken,
        profile: _headers._idToken,
      },
    })
    .then((response) => {
      const {status} = response;
      if (status === 200 || status === 201) {
        return response && response.data ? response.data : [];
      } else if (status === 500 || status >= 500) {
        return 'ERR_NETWORK';
      }
    })
    .catch((err) => {
      return err.code;
    });
  return resp;
};

// POST Request V2
export const makePostRequest_V2 = async (params) => {
  const { endpoint, body } = params;
  const _headers = _apiHeaderConfigs();
  try {
    const response = await axiosInstance.post(BASE_URL + endpoint, body, {
      headers: {
        'Content-Type': 'application/json',
        authorization: _headers._accessToken,
        profile: _headers._idToken,
      },
    });
    return handleResponse(response);
  } catch (error) {
    return handleError(error);
  }
};

const makePutRequest = async (params) => {
  const {endpoint, body} = params;
  const _headers = _apiHeaderConfigs();
  const resp = await axiosInstance
    .put(BASE_URL + endpoint, body, {
      headers: {
        'Content-Type': 'application/json',
        authorization: _headers._accessToken,
        profile: _headers._idToken,
      },
    })
    .then((response) => {
      const {status} = response;
      if (status === 200 || status === 201) {
        return response && response.data ? response.data : [];
      } else if (status === 500 || status >= 500) {
        return 'ERR_NETWORK';
      }
    })
    .catch((err) => {
      return err.code;
    });
  return resp;
};

const makePatchRequest = async (params) => {
  const {endpoint, body} = params;
  const _headers = _apiHeaderConfigs();
  const resp = await axiosInstance
    .patch(BASE_URL + endpoint, body, {
      headers: {
        'Content-Type': 'application/json',
        authorization: _headers._accessToken,
        profile: _headers._idToken,
      },
    })
    .then((response) => {
      const {status} = response;
      if (status === 200 || status === 201) {
        return response && response.data ? response.data : [];
      } else if (status === 500 || status >= 500) {
        return 'ERR_NETWORK';
      }
    })
    .catch((err) => {
      return err.code;
    });
  return resp;
};

const makeDeleteRequest = async (params) => {
  const {endpoint, body} = params;
  const _headers = _apiHeaderConfigs();
  if (body?.audienceGroups?.deleteAudGrpIds?.length > 0) {
    const resp = await axiosInstance
      .delete(BASE_URL + endpoint, {
        headers: {
          'Content-Type': 'application/json',
          authorization: _headers._accessToken,
          profile: _headers._idToken,
        },
        data: body,
      })
      .then((response) => {
        const {status} = response;
        if (status === 200 || status === 201) {
          return response && response.data ? response.data : [];
        }
      })
      .catch((err) => {
        return err.code;
      });
    return resp;
  } else {
    const resp = await axiosInstance
      .delete(BASE_URL + endpoint, {
        headers: {
          'Content-Type': 'application/json',
          authorization: _headers._accessToken,
          profile: _headers._idToken,
        },
        data: body,
      })
      .then((response) => {
        const {status} = response;
        if (status === 200 || status === 201) {
          return response && response.data ? response.data : [];
        }
      })
      .catch((err) => {
        return err.code;
      });
    return resp;
  }
};

// Helper to handle errors
const handleError = (error) => {
  if (error.response) {
    // The request was made and the server responded with a status code
    const message = error.response.data?.message || error.response.statusText || 'An unexpected error occurred.';
    console.error(`[httpUtil] >> Server error: ${message}`);
    return {
      error: 'ERR_SERVER',
      success: false,
      message: message,
      status : error.response?.status,
    };
  } else if (error.request) {
    // The request was made but no response was received
    console.error('[httpUtil] >> No response received from the server.');
    return {
      error: 'ERR_NETWORK',
      success: false,
      message: 'No response received from the server.',
    };
  } else {
    // Something happened in setting up the request that triggered an Error
    console.error(`[httpUtil] >> Error in request setup: ${error.message}`);
    return {
      error: 'ERR_UNKNOWN',
      success: false,
      message: `Error in request setup: ${error.message}`,
    };
  }
};

// Helper to handle successful responses
const handleResponse = (response) => {
  const { status } = response;
  if (status >= 200 && status < 300) {
    return { success: true, data: response?.data || [] }
  } else {
    return { error: 'ERR_NETWORK', success: false, message: `Unexpected status code: ${status}` };
  }
};
