import React from "react";
import styles from "./Alertmodal.module.scss"

const AlertModal =({onClose, onExit})=>{
    return (
        <div className={`${styles.modal_wrapper}`}>
          <div className={`${styles.custom_overlay} ${styles.tablet_open}`}>   
            <div className={styles.cross_icon} onClick={onClose}></div>  
            <div className={styles.header_wrapper}>
              <div className={styles.headerTitle}>Exit Without Saving? </div> 
              <div className={styles.sub_heading}>Are you sure you want to exit without saving the changes?</div>
            </div>
            <div className={styles.btn_wrapper}>
              <button className={styles.btn} onClick={onClose}>Cancel</button>
              <button className={`${styles.btn} ${styles.secondary}`} onClick={onExit}>Exit</button>
            </div>
          </div>
        </div>
      );
}

export default AlertModal