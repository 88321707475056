/**
 * Represents a rich grid component that supports checkbox selection and sorting.
 *
 * @param {Column[]} columns - A list of column objects with rendering logic and styles for each column. The length determines the number of columns in the grid.
 * @typedef {Object} Column
 * @property {string} field - Field for sorting the rich grid. If a column does not have a field, it is not sortable.
 * @property {string|React.Component} header - Heading of the column. If not set, the heading will be empty.
 * @property {RenderLogic} renderLogic - Rendering logic given row data. If not set, the column will be empty.
 * @property {Object} containerStyles - Any styling of the column item's container can be passed via this prop.
 * @property {function} [cellStyleFn] - Optional function to provide custom styles for the cell based on row data.
 *
 * @param {Object[]} data - A list of objects; the structure of the objects does not matter.
 * @param {function} extractRowKey - Callback function called on each row to get a unique key.
 *
 * @param {SortProps} sortProps - Props needed for the grid to be responsive to sort actions.
 * @typedef {Object} SortProps
 * @property {string} field - Field to sort on, one of the headers.
 * @property {string} dir - Direction of sort, should be one of the values in the SORT_DIRECTIONS enum.
 * @property {function} onChange - Callback function to be called on sort change. If not passed, the grid will not respond to sort changes.
 *
 * @param {boolean} [selectable] - Determines if rows can be selected.
 * @param {Object[]} [selectedRows] - List of currently selected rows.
 * @param {function} onSelectionChangeCallBack - Callback function called when the selection changes.
 * @param {function} onHeaderSelectionChangeCallBack - Callback function called when the header checkbox changes.
 * @param {function} rowStyleFn - Optional function to provide custom styles for rows.
 * @param {function} NoRecordsFoundComponent - Component to render when no records are found. Defaults to a simple message.
 *
 * Note: The grid itself does not sort the data; it displays sorting options and helps with callbacks for changing sort order.
 */

import React, { useEffect, useRef, useState } from 'react';
import { SORT_DIRECTIONS } from './constants';
import styles from './RichGrid.module.scss';
import './richgrid.scss';
import CheckBoxBlue from '../checkboxBlue';
import table_sorting_icon from '../../../styles/images/table_sorting_icon.svg';
import LoadingShimmerList from '../LoadingShimmerList';

export default function RichGrid({
  isLoading = false,
  columns = [],
  data = [],
  selectable = null,
  selectedRows = [],
  onSelectionChangeCallBack = (rows) => {},
  onHeaderSelectionChangeCallBack = (rows) => {},
  extractRowKey,
  sortProps = {
    field: null,
    dir: SORT_DIRECTIONS.NONE,
    onChange: () => {},
  },
  rowStyleFn = null,
  setSelectTotalRecordsCb = () => {},
  NoRecordsFoundComponent = () => <h5>No Records Found</h5>,
  paginationProps = {
    pageSize: 10,
    keys: [],
    currentPageNo: 1,
  },
  onClickShowNextRecordsCb = () => {},
  onClickShowPreviousRecordsCb = () => {},
  onClickPageNoCb = () => {}
}) {
  const [headerCheckbox, setHeaderCheckbox] = useState(false);
  const { pageSize, keys, currentPageNo } = paginationProps;

  // Step 1: Create a ref for the page numbers
  const pageRefs = useRef({});

  // Step 2: Scroll to the page on load or when pageNo changes
  useEffect(() => {
    setTimeout(() => {
      if (pageRefs.current[currentPageNo]) {
        pageRefs.current[currentPageNo].scrollIntoView({
          behavior: 'smooth',
          inline: 'center', // Scroll to center of the page
        });
      }
    }, 700)
  }, [currentPageNo, keys]);

  const getChangedSortPropsOnClick = (changedSortField) => {
    if (sortProps.field == changedSortField) {
      if (sortProps.dir == SORT_DIRECTIONS.ASC) {
        return { field: changedSortField, dir: SORT_DIRECTIONS.DESC };
      } else {
        return { field: changedSortField, dir: SORT_DIRECTIONS.ASC };
      }
    } else {
      return { field: changedSortField, dir: SORT_DIRECTIONS.ASC };
    }
  };

  const isColumnHeaderChecked = () => {
    // Return false if there's no data
    if (!data || data.length === 0) {
      return false;
    }
    
    const selectedRowIds = selectedRows.map(row => extractRowKey(row));
    const dataRowIds = data.map(row => extractRowKey(row));
  
    // Check if every row in `data` has its corresponding rowId in `selectedRows`
    return dataRowIds.every(rowId => selectedRowIds.includes(rowId));
  }

  const selectableColumn = {
    field: 'checkbox',
    isCheckboxField: true,
    header: (
      <CheckBoxBlue
        isChecked={isColumnHeaderChecked()}
        handleCheckClick={(isChecked) => {
          onHeaderSelectionChangeCallBack(isChecked ? data : []);
          setHeaderCheckbox(isChecked);
          if (!isChecked) {
            setSelectTotalRecordsCb(isChecked);
          }
        }}
      />
    ),
    renderLogic: (row, indx) => (
      <CheckBoxBlue
        isChecked={selectedRows.find(
          (selectedRow) => extractRowKey(row) === extractRowKey(selectedRow),
        )}
        handleCheckClick={(isChecked) => {
          let newSelectedRows;
          if (!isChecked) {
            newSelectedRows = [...selectedRows].filter(
              (selectedRow) => extractRowKey(row) !== extractRowKey(selectedRow),
            );
          } else {
            newSelectedRows = [...selectedRows];
            newSelectedRows.push(row);
          }
          onSelectionChangeCallBack(newSelectedRows, indx);
          if (!isChecked) {
            setSelectTotalRecordsCb(isChecked);
          }
        }}
      />
    ),
  };

  const gridColumns = [...columns];
  if (selectable) gridColumns.unshift(selectableColumn);

  return (
    <div className={styles.container}>
      {isLoading ? (
        <LoadingShimmerList count={10} />
      ) : (
        <>
          <div className={styles.tableContainer}>
            <div id="richGridTable" className={styles.table}>
              <div style={{width:'100%'}}>
                <div key="richgrid-thead" id='headerRow' className={styles.headerRowContainer}>
                  {gridColumns.map((column, idx) => {
                    const isSortable = !!column.field;
                    const isSorted =
                      isSortable &&
                      sortProps.field === column.field &&
                      sortProps.dir !== SORT_DIRECTIONS.NONE;

                    return (
                      <>
                        {column.header}
                      </>
                    );
                  })}
                </div>
              </div>
              {data.length > 0  && <div className={styles.tableBodyContainer}>
                {data.map((row, idx) => {
                  const rowStyle = rowStyleFn ? rowStyleFn(row, idx) : [];
                  return (
                    <div
                      key={`table-row-${idx}`}
                      className={[styles.tableBodyRowContainer, rowStyle].join(' ')}
                    >
                      {gridColumns.map((column, idx) => (
                        <>
                          {column.renderLogic(row, idx)}
                        </>
                      ))}
                    </div>
                  );
                })}
              </div>}
            </div>
          </div>

          {/* Table Pagination */}
          {keys?.length > 1 && (
            <div className={styles.paginationContainer}>
              {data?.length > 0 && (
                <button
                  className={styles.paginationNextBtn}
                  onClick={onClickShowPreviousRecordsCb}
                  disabled={currentPageNo === 1}
                >
                  {'<'}
                </button>
              )}

              <div className={styles?.paginationPageNoContainer}>
                {keys?.map((k, i) => {
                  return (
                    <div
                      key={k?.exclusiveStartKey}
                      ref={(el) => (pageRefs.current[i + 1] = el)} // Reference each page number div
                      style={{
                        padding: '5px 14px',
                        background: i + 1 === currentPageNo ? '#38539a' : '',
                        color: i + 1 === currentPageNo ? 'white' : '#2c2c2c',
                        borderRadius: '50px',
                        cursor: 'pointer',
                      }}
                      onClick={() => onClickPageNoCb(i)}
                    >
                      <span>{i + 1}</span>
                    </div>
                  );
                })}
              </div>

              {data?.length > 0 && (
                <button
                  className={styles.paginationNextBtn}
                  onClick={onClickShowNextRecordsCb}
                  disabled={!keys[currentPageNo]?.exclusiveStartKey}
                >
                  {'>'}
                </button>
              )}
            </div>
          )}

          {/* No Records Found Component */}
          {data.length === 0 ? NoRecordsFoundComponent() : null}
        </>
      )}
    </div>
  );
}

